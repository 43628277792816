import { GiftsEnum } from '@/app/gifts/gifts.types';
import { IUserPhotosData, IUserVideosData, UserPhotosDTO } from '@/app/users/users.dto';

export type LanguageLevel = 'basic' | 'upper intermediate' | 'fluent';

export type UserGender = 'male' | 'female';

export type UserBody = 'athletic' | 'overweight' | 'strong' | 'plump' | 'medium-build' | 'slim' | 'thin';

export type UserHairColor = 'dark' | 'fair' | 'red' | 'brown' | 'blond' | 'gray';

export type UserEyeColor = 'black' | 'green' | 'gray' | 'brown' | 'light-brown' | 'blue';

export type UserReligion = 'Christianity' | 'Catholicism' | 'Buddhism' | 'Hinduism' | 'Islam' | 'other';

export type UserFamily = 'no' | 'single' | 'divorced' | 'widow' | 'never been married';

export type UserChildren =
  | 'no'
  | 'no but I want to have children'
  | 'have children'
  | 'have children but living separately'
  | 'have adult children'
  | 'I have no children ';

export enum UserChildrenEnum {
  'no' = 'No children',
  'no but I want to have children' = 'No but I want to have children',
  'have children' = 'Have children',
  'have children but living separately' = 'Have children, but living separately',
  'have adult children' = 'Have adult children',
}

export type UserSmoke = 'no' | 'rarely' | 'often' | 'I do not smoke';

export type UserDrink = 'no' | 'rarely' | 'occasionally' | 'often' | 'I do not drink';

export type UserEducation =
  | 'no'
  | 'Middle School'
  | 'High school'
  | 'Student'
  | "Bachelor's Degree"
  | "Master's Degree"
  | 'Doctoral Level';

export type UserMarital = 'no' | 'single' | 'never been married' | 'divorced' | 'widow';

export interface ChatDialog {
  id: string;
  date: Date;
  text: string;
  img?: {
    isPaid?: boolean;
    photoId: string;
    small: string;
    origin: string;
    large: string;
  };
  video?: {
    isPaid?: boolean;
    videoId: string;
    thumbnail: string;
    origin: string;
  };
  translate: string;
  isDisconnected?: boolean;
}

export interface UserProfile {
  id: string;
  userId: string;
  pageId: number;
  name: string;
  activeAt?: string;
  avatar?: string;
  surname?: string;
  gender: UserGender;
  nickname?: string;
  birthday?: string;
  body?: UserBody;
  height?: number;
  weight?: number;
  hairColor?: UserHairColor;
  eyeColor?: UserEyeColor;
  religion?: UserReligion;
  children?: UserChildren;
  smoke?: UserSmoke;
  drink?: UserDrink;
  education?: UserEducation;
  profession?: string;
  aboutMe?: string;
  celebrationBirthday?: boolean;
  celebrationChristmasDay?: boolean;
  celebrationNewYearsDay?: boolean;
  celebrationEaster?: boolean;
  celebrationValentinesDay?: boolean;
  celebrationHalloween?: boolean;
  celebrationWomensDay?: boolean;
  country?: string;
  city?: string;
  langEnglish?: LanguageLevel;
  langSpanish?: LanguageLevel;
  langFrench?: LanguageLevel;
  langPortuguese?: LanguageLevel;
  langGerman?: LanguageLevel;
  family: UserMarital;
  interests?: string;
  goals?: string;
  chat?: ChatDialog[];
  isOnline: boolean;
  status?: string;

  perfectAgeFrom?: number | null;
  perfectAgeTo?: number | null;
  perfectHair?: UserHairColor;
  perfectEyes?: UserEyeColor;
  perfectBody?: UserBody;
  perfectHeightFrom?: number | null;
  perfectHeightTo?: number | null;

  perfectWeight?: number;
  perfectLangEnglish?: LanguageLevel;
  perfectChildren?: UserChildren;
  perfectType?: string;
  isBookmarks?: boolean;
}

export interface UserProfileState extends UserProfile {
  publicPhotos?: IUserPhotosData;
  privatePhotos?: IUserPhotosData;
  publicVideos?: IUserVideosData;
  privateVideos?: IUserVideosData;
  photosIsLoading?: boolean;
  needToCharge: boolean;
  itHasMyMessage: boolean;
  itHasReferenceMessage: boolean;
  itHasNewMessage: boolean;
}

export interface ISettings {
  companyAddress: string | null;
  companyName: string | null;
  companyCountry: string | null;
  companyPhone: string | null;
  supportEmail: string | null;
  emailForPaypal: string | null;
  siteUrl: string | null;
  siteName: string | null;
  rnCompany: string | null;
  creditsMessageOpen: number;
  creditsMessageSend: number;
  creditsDialogPhotoSend: number;
  creditsDialogPhotoOpen: number;
  creditsDialogVideoSend: number;
  creditsDialogVideoOpen: number;
  creditsProfilesBlacklistAdd: number;
  creditsChatPhotoOpen: number;
  creditsChatPhotoSend: number;
  creditsChatTextMin: number;
  creditsChatVideoMin: number;
  creditsChatVideoOpen: number;
  creditsChatVideoSend: number;
  creditsMessageNew: number;
  creditsMessagePhotoOpen: number;
  creditsMessageVideoOpen: number;
  creditsMessageVideoSend: number;
  creditsDialogTextSend: number;
  creditsRealInfo: number;
  sysTimeWhileNew: number;
  paymentsFlexpay: boolean;
  paymentsStripe: boolean;
  paymentsStripebg01: boolean;
  paymentsCcbill: boolean;
  paymentsCcbillUsd: boolean;
  paymentsInoviopay: boolean;
  paymentsInoviopay3ds2: boolean;
  paymentsNetvalve: boolean;
  liveChat: boolean;
}

export interface IAcquiring {
  gateway: string;
}

export interface UserSliderProfile {
  id?: string;
  userId: string;
  pageId: number;
  name: string;
  avatar?: string | null;
  surname?: string;
  birthday?: string;
  activeAt?: string;
}

export interface IVideoInviteProfile {
  profileId: string;
  avatar: string;
  name: string;
  date: Date;
}

export interface DialogMessage {
  id: string;
  text: string;
  translate: string;
  createdAt: Date;
  seen: string;
  unreadCount: number;
  pinRanked: number;
  virtualGift: {
    id: string;
    name: string;
    image: string;
    category: GiftsEnum;
  };
  photo: {
    id: string;
    paidAt: string;
    origin: string;
    large: string;
    small: string;
  };
  video: {
    id: string;
    paidAt: string;
    origin: string;
    thumbnail: string;
  };
  senderProfile: {
    id: string;
    name: string;
    avatar: string;
    birthday: string;
    activeAt: string;
    pageId: number;
  };
  receiverProfile: {
    id: string;
    name: string;
    avatar: string;
    birthday: string;
    activeAt: string;
    pageId: number;
  };
  isBookmark: boolean;
  userDialogs: UserDialog;
}

export interface UserDialog {
  prevPage: boolean;
  nextPage: boolean;
  data: DialogMessage[];
}

export interface IBlackList {
  id: string;
  createdAt: string;
  ownerProfileId: string;
  blockedProfileId: string;
  profileId: string;
  profileName: string;
  profileAvatar: string;
}

export interface DialogInviteMessage extends DialogMessage {
  endTime: number
}
