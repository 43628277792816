export const GET_DIALOG_INVITES_REQUEST = 'GET_DIALOG_INVITES_REQUEST';
export const GET_DIALOG_INVITES_SUCCESS = 'GET_DIALOG_INVITES_SUCCESS';

export const UPDATE_DIALOG_INVITE_SOCKET_DATA = 'UPDATE_DIALOG_INVITE_SOCKET_DATA';

export const GENERATE_NEW_DIALOG_INVITE_ACTIVE_DATA = 'GENERATE_NEW_DIALOG_INVITE_ACTIVE_DATA';

export const SET_DIALOG_INVITE_ACTIVE_DATA = 'SET_DIALOG_INVITE_ACTIVE_DATA';

export const READ_USER_ACTIVE_DIALOG_INVITES = 'READ_USER_ACTIVE_DIALOG_INVITES';

export const HIDE_DIALOG_INVITE_ACTIVE_ACTION = 'HIDE_DIALOG_INVITE_ACTIVE_ACTION';
export const SKIP_DIALOG_INVITE_ACTIVE_ACTION = 'SKIP_DIALOG_INVITE_ACTIVE_ACTION';
export const SET_SKIPPED_INVITES_DATA = 'SET_SKIPPED_INVITES_DATA';
export const SET_UPDATED_DIALOG_INVITES_DATA = 'SET_UPDATED_DIALOG_INVITES_DATA';
