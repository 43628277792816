import { all } from "redux-saga/effects";
import authSagas from "./auth/auth.saga";
import mailSagas from "./mails/mails.saga";
import giftSagas from "./gifts/gifts.saga";
import userSagas from "./users/users.saga";
import dialogInvites from "./dialogInvites/dialogInvites.saga";

export default function* rootSaga(): Generator<any> {
  yield all([...authSagas, ...userSagas, ...mailSagas, ...giftSagas, ...dialogInvites]);
}
