import React, { useCallback, useEffect, useRef, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "@/app/hooks";
import { useNavigate } from "react-router-dom";
import { DialogInviteMessage } from "@/types/user-profile.types";
import { DialogToUsersDialogsSuccess, } from "@/app/users/users.actions";
import Socket from "@/services/Socket";
import { socketRoutes } from "@/components/socketRoutes";
import {
  generateNewDialogInviteActiveDataAction,
  getDialogInvitesRequest,
  hideDialogInviteActiveAction,
  skipDialogInviteActiveAction,
  updateDialogInviteSocketData
} from "@/app/dialogInvites/dialogInvites.actions";
import { selectDialogInvitesActive } from "@/app/dialogInvites/dialogInvites.selectors";

const ADD_NEW_ITEM_INTERVAL = 10000;
const CHECK_ITEM_TO_DELETE_INTERVAL = 1000;

export const useDialogAutoInvitesData = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showItems, setShowItems] = useState(true);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [semiExpandedItems, setSemiExpandedItems] = useState<string[]>([]);
  const newItemIntervalId = useRef<NodeJS.Timeout | null>(null);
  const checkItemsForRemoveIntervalId = useRef<NodeJS.Timeout | null>(null);
  const visibleInvites = useRef<DialogInviteMessage[]>([]);
  const dialogInvitesActive = useSelector(selectDialogInvitesActive);
  const myUser = useAppSelector((state) => state.users.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getDialogInvitesRequest());
  }, [dispatch]);

  useEffect(() => {
    if (Socket && Socket.socket) {
      Socket.off(socketRoutes.dialogNotificationAutoinvite);

      Socket.on(socketRoutes.dialogNotificationAutoinvite, (data: any) => {
        batch(() => {
          dispatch(updateDialogInviteSocketData({ message: data.data }));
          dispatch(DialogToUsersDialogsSuccess(data.data, true));
        });
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (dialogInvitesActive.length > visibleInvites.current.length) {
      setSemiExpandedItems((prev) => [...prev, dialogInvitesActive[dialogInvitesActive.length - 1].id])
      visibleInvites.current = dialogInvitesActive
    } else if (dialogInvitesActive.length < visibleInvites.current.length) {
      visibleInvites.current = dialogInvitesActive
    }
  }, [dialogInvitesActive]);

  useEffect(() => {
    if (dialogInvitesActive.length > 0 && !isVisible) {
      setIsVisible(true);
    } else if (dialogInvitesActive.length === 0 && isVisible) {
      setIsVisible(false);
    }
  }, [dialogInvitesActive, isVisible]);

  useEffect(() => {
    const addNewItem = () => {
      if (dialogInvitesActive.length < 5) {
        dispatch(generateNewDialogInviteActiveDataAction());
      }
    };
    const handleChange = () => {
      if (document.visibilityState !== 'visible') {
        if (newItemIntervalId.current) {
          clearInterval(newItemIntervalId.current);
        }
      } else {
        newItemIntervalId.current = setInterval(addNewItem, ADD_NEW_ITEM_INTERVAL);
      }
    };
    if (document.visibilityState === 'visible') {
      newItemIntervalId.current = setInterval(addNewItem, ADD_NEW_ITEM_INTERVAL);
    }
    document.addEventListener("visibilitychange", handleChange);

    return () => {
      if (newItemIntervalId.current) {
        clearInterval(newItemIntervalId.current)
      }
      document.removeEventListener("visibilitychange", handleChange);
    };
  }, [dialogInvitesActive, dispatch]);


  useEffect(() => {
    const checkEndTime = () => {
      const currentTime = new Date().getTime();
      const diedInvite = dialogInvitesActive.find((invite) => invite.endTime <= currentTime);
      if (diedInvite) {
        dispatch(hideDialogInviteActiveAction({ dialogInvite: diedInvite }));
        setExpandedItems((prev) => prev.filter((id) => id !== diedInvite.id));
      }
    };
    const handleVisibilityChange = () => {
      if (document.visibilityState !== 'visible') {
        if (checkItemsForRemoveIntervalId.current) {
          clearInterval(checkItemsForRemoveIntervalId.current);
        }
      } else {
        checkItemsForRemoveIntervalId.current = setInterval(checkEndTime, CHECK_ITEM_TO_DELETE_INTERVAL);
      }
    };
    if (document.visibilityState === 'visible') {
      checkItemsForRemoveIntervalId.current = setInterval(checkEndTime, CHECK_ITEM_TO_DELETE_INTERVAL);
    }
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      if (checkItemsForRemoveIntervalId.current) {
        clearInterval(checkItemsForRemoveIntervalId.current)
      }
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [dialogInvitesActive, dispatch]);

  const removeSemiExpandedItem = useCallback((id) => {
    setSemiExpandedItems((prevState) => prevState.filter((itemId) => itemId !== id));
  }, [])

  const onNavigate = useCallback((url: string) => {
    if (newItemIntervalId.current) {
      clearInterval(newItemIntervalId.current)
    }
    if (checkItemsForRemoveIntervalId.current) {
      clearInterval(checkItemsForRemoveIntervalId.current)
    }
    navigate(url)
  }, [navigate])

  const handleItemClick = (invite: DialogInviteMessage) => {
    if (expandedItems.includes(invite.id)) {
      dispatch(hideDialogInviteActiveAction({ dialogInvite: invite }));
      onNavigate(`/dialog/${myUser?.profile?.id}/${invite.senderProfile.id}`)
    } else {
      setSemiExpandedItems((prev) => prev.filter((itemId) => itemId !== invite.id));
      setExpandedItems((prev) => [...prev, invite.id]);
    }
  };

  const toggleItems = () => {
    setShowItems((prev) => !prev);
    setExpandedItems([])
  };

  const handleSkip = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, dialogInvite: DialogInviteMessage) => {
    e.stopPropagation();
    setExpandedItems((prev) => prev.filter((itemId) => itemId !== dialogInvite.id))
    dispatch(skipDialogInviteActiveAction({ dialogInvite }));
  };

  return {
    isVisible,
    visibleInvites: dialogInvitesActive,
    showItems,
    toggleItems,
    expandedItems,
    handleItemClick,
    handleSkip,
    semiExpandedItems,
    removeSemiExpandedItem
  }
}
