import { CircularProgress, Fab, Theme } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ReactComponent as PencilIcon } from "@/assets/icons/pencil.svg";
import UserMessage from "../user-message";
import { makeStyles } from "@mui/styles";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { MailKind } from "@/app/mails/mails.types";
import BaseButton from "@/components/base/button.component";
import { checkMessage, getMailsRequest } from "@/app/mails/mails.actions";

const useStyles = makeStyles((theme: Theme) => ({
  messagesContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    flexDirection: "column",
    "& > $userMessage:not(:first-child)": {
      marginTop: 10,
    },
  },
  userMessage: {},
  loadMoreBtn: {
    maxWidth: 200,
    minWidth: 200,
  },
  loadMoreWrapper: {
    alignSelf: "center",
    minWidth: "200px !important",
  },
  createMessageButton: {
    position: 'sticky',
    bottom: '75px',
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  loadButtonSection: {
    display: 'flex',
    flex: '1',
    justifyContent: 'center',
    marginTop: '28px'
  },
  noMessages: {
    margin: '0 auto',
    border: '2px dashed #503EB6',
    padding: '10px',
  }
}));

const stylesOverrides = {
  createMessageButton: {
    boxShadow: '0px 10px 30px rgb(234 65 96 / 50%)',
  }
};

type MessageItem = {
  id: string;
  checked: boolean;
  isUnread: boolean;
};

type MessagesSectionProps = {
  messagesKind: MailKind;
  writeMessagehandler: () => void;
};

const MessagesSection = ({ messagesKind, writeMessagehandler }: MessagesSectionProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const mails = useAppSelector((state) => state.mails[messagesKind + "Mails"]);
  const isLoading = useAppSelector((state) => state.mails.isLoading);
  const selectedMessages = useAppSelector((state) => state.mails.chosenIds);
  const isPaginationOver = useAppSelector((state) => state.mails.isPaginationOver);
  const [listItems, setListItems] = useState(mails);

  useEffect(() => {
    setListItems(mails);
  }, [mails]);

  const loadMore = useCallback(
    () => {
      dispatch(getMailsRequest(messagesKind));
    },
    [dispatch, messagesKind]
  );

  const toggleCheckboxValue = useCallback(
    (message: MessageItem) => {
      dispatch(checkMessage(message.id));
    },
    [dispatch]
  );

  const getLoadButton = useMemo(() => {
    if (isPaginationOver) return null;
    if (isLoading) return <CircularProgress color="secondary" style={{ margin: "0 auto" }} />;


    return <BaseButton
      text="Load More (20)"
      color="primary"
      wrapperClass={classes.loadMoreWrapper}
      className={classes.loadMoreBtn}
      onClick={loadMore}
    />

  }, [classes.loadMoreBtn, classes.loadMoreWrapper, isLoading, isPaginationOver, loadMore])

  return (
    <section className={classes.messagesContainer}>
      {listItems.length > 0 ?
        listItems.map((message) => (
          <UserMessage
            key={message.id}
            kind={messagesKind}
            isUnread={!!message.seen}
            avatar={message.profileAvatar}
            message={message}
            subject={message.subject}
            isChecked={selectedMessages.has(message.id)}
            onChange={toggleCheckboxValue}
            wrapperClassName={classes.userMessage}
            timestamp={new Date(message.createdAt)}
            userId={message.profileId}
            name={message.profileName}
            age={message.profileBirthday}
          />
        )): <div className={classes.noMessages}>There are no messages</div>}
      <div className={classes.loadButtonSection}>
        {getLoadButton}
      </div>
      <div className={classes.createMessageButton}>
        <Fab
          color="primary"
          aria-label="add"
          size="small"
          style={stylesOverrides.createMessageButton}
          onClick={writeMessagehandler}
        >
          <PencilIcon />
        </Fab>
      </div>
    </section>
  );
};

export default MessagesSection;
