import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DialogMessage, ISettings, UserDialog, UserProfile } from '@/types/user-profile.types';
import clsx from 'clsx';
import { formatAMPM } from '@/helpers/helpers';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { RevealPhotoComponent } from './reveal-photo.dialog.component';
import useBalanceCheckHook from '@/hooks/useBalanceCheckHook';
import { RevealVideoComponent } from './reveal-video.dialog.component';
import UploadVideoItem from '@/components/base/upload-video-item';
import { DialogAvatar } from "@/components/dialog-container/components/DialogAvatar/DialogAvatar";
import { DialogMessageContainer } from "@/components/dialog-container/components/DialogContainer/DialogMessageContainer";
import { DialogPhoto } from "@/components/dialog-container/components/DialogPhoto/DialogPhoto";
import { Gallery } from "react-photoswipe-gallery";
import ProfileUserSlider from "@/components/sliders/profile-user-slider.component";
import { DialogVideo } from "@/components/dialog-container/components/DialogVideo/DialogVideo";

export interface IProps {
  referenceUser: UserProfile;
  currentUser: UserProfile;
  initialMessage?: string;
  getReferenceUserDialog: (id: string, offset: number) => void;
  translateHisText(message: { text: string; translate: string; id: string }): void;
  revealPhoto: (message: DialogMessage) => void;
  revealVideo: (message: DialogMessage) => void;
  userDialog: UserDialog;
}

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
  },
  scrollContainer: {
    overflowY: 'scroll',
    padding: '0',
    marginTop: '0',
    height: '100%',
    // height: (props: IProps) =>
    [theme.breakpoints.up('md')]: {
      // height: (props: IProps) =>
    },
    [theme.breakpoints.up('lg')]: {
      // height: (props: IProps) =>
    },
  },
  avatar: {
    width: '56px',
    height: '56px',
    borderRadius: '60px',
    [theme.breakpoints.down('sm')]: {
      width: '30px',
      height: '30px',
    },
  },
  avatarImage: {
    width: '56px',
    height: '56px',
    borderRadius: '60px',
    [theme.breakpoints.down('sm')]: {
      width: '30px',
      height: '30px',
    },
  },
  avatarRight: {
    paddingRight: '16px',
    '&::after': {
      background: '#FEF3F3',
    },
  },
  avatarLeft: {
    paddingLeft: '16px',
    '&::after': {
      background: '#F0FBFE',
    },
  },
  message: {
    textAlign: 'left',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  customMessage: {
    textAlign: 'center',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '28px',
    color: '#7D7D7F',
    background: 'rgba(215, 211, 238, 0.25)',
    borderRadius: '50px',
    margin: '28px 0 0 0',
  },
  messageDate: {
    fontWeight: '300',
    fontSize: '12px',
    lineHeight: '20px',
    color: '#808080',
  },
  leftMessageBackground: {
    background: '#FEF3F3',
  },
  rightMessageBackground: {
    background: '#F0FBFE',
  },
  male: {
    width: '100%',
    borderRadius: '10px',
  },
  female: {
    width: '100%',
    borderRadius: '10px',
  },
  ownMessage: {
    display: 'flex',
    paddingTop: '28px',
  },
  leftMessage: {
    padding: '28px 72px 0 0',
    [theme.breakpoints.down('sm')]: {
      padding: '28px 0 0 0',
    },
  },
  rightMessage: {
    padding: '28px 0 0 72px',
    [theme.breakpoints.down('sm')]: {
      padding: '28px 0 0 0',
    },
  },
  messageBody: {
    padding: '10px 28px 28px 14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      padding: '10px 8px 28px 14px',
    },

    '& img': {
      width: '125px',
      height: '125px',
      borderRadius: '10px',
      objectFit: 'cover',
      objectPosition: 'center',
      cursor: 'pointer',
    },
  },
  messageWrapper: {
    position: 'relative',
    textAlign: 'left',
  },
  time: {
    fontWeight: '300',
    fontSize: '12px',
    lineHeight: '20px',
    color: '#808080',
    padding: '0 0 10px 14px',
    position: 'absolute',
    bottom: 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      padding: '0 0 0px 14px',
    },
  },
  LeftBox: {
    position: 'relative',
    '&::after': {
      content: "''",
      position: 'absolute',
      top: '17px',
      left: '-16px',
      width: '22px',
      height: '22px',
      transition: 'opacity',
      borderRadius: '2px',
      transform: 'rotate(45deg)',
      [theme.breakpoints.down('sm')]: {
        top: '5px',
      },
    },
  },
  RightBox: {
    position: 'relative',
    '&::after': {
      content: "''",
      position: 'absolute',
      top: '17px',
      right: '-16px',
      width: '22px',
      height: '22px',
      transition: 'opacity',
      borderRadius: '2px',
      transform: 'rotate(45deg)',
      [theme.breakpoints.down('sm')]: {
        top: '5px',
      },
    },
  },
  profileSlideWrapper: {
    marginBottom: '5px',

    '& .pswp__zoom-wrap': {
      display: 'flex',
      justifyContent: 'center',
    },

    '& .swiper-slide': {
      marginRight: '0px !important',
      width: '125px !important',
      height: '125px !important',
      justifyContent: 'center',
    },

    '& .pswp__img': {
      width: 'auto !important',
      position: 'relative !important',
    },
    '& .pswp__img--placeholder': {
      display: 'none',
    },
  },
  loadMorePhoto: {
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '15px',
    color: '#503EB6',
    background: '#EEEEEE',
    padding: '10px 9px 11px 12px',
    borderRadius: '20px',
    cursor: 'pointer',
  },
  virtualGift: {
    width: '200px !important',
    height: 'unset !important',
    cursor: 'auto',
  },
  dropzoneVideo: {
    width: '125px !important',
    height: '125px !important',
    margin: '0px !important',
    borderRadius: '10px !important',
    marginBottom: '5px !important',

    '& video': {
      borderRadius: '10px !important',
    },
  },
}));

const DialogMessages = ({ ...props }: IProps) => {
  const classes = useStyles();

  const windowWidth = useMemo(() => window.innerWidth, []);
  const windowHeight = useMemo(() => window.innerHeight - 90, []);

  const {
    referenceUser,
    currentUser,
    revealPhoto,
    revealVideo,
    userDialog,
    getReferenceUserDialog,
  } = props;

  const messagesEndRef = useRef(null);

  const [openPhotoDialog, setPhotoOpenDialog] = useState(false);
  const [openVideoDialog, setVideoOpenDialog] = useState(false);

  const [revealPhotoMessage, setRevealPhotoMessage] = useState(null);
  const [revealVideoMessage, setRevealVideoMessage] = useState(null);

  const handleClosePhotoDialog = useCallback(() => {
    setRevealPhotoMessage(null);
    setPhotoOpenDialog(false);
  }, []);

  const handleCloseVideoDialog = useCallback(() => {
    setRevealVideoMessage(null);
    setVideoOpenDialog(false);
  }, []);

  const revealPhotoCallback = useCallback(() => {
    revealPhoto(revealPhotoMessage);
    handleClosePhotoDialog();
  }, [handleClosePhotoDialog, revealPhotoMessage, revealPhoto]);

  const revealVideoCallback = useCallback(() => {
    revealVideo(revealVideoMessage);
    handleClosePhotoDialog();
  }, [handleClosePhotoDialog, revealVideoMessage, revealVideo]);

  const { creditsDialogPhotoOpen, creditsDialogVideoOpen } = useAppSelector<ISettings>((state) => state.users.settings);
  const wallet = useAppSelector((state) => state.users.wallet);
  const { checkBalance } = useBalanceCheckHook(wallet?.balance || 0);

  const handleOpenPhotoDialog = useCallback((message: DialogMessage) => {
    checkBalance(creditsDialogPhotoOpen, () => {
      setRevealPhotoMessage(message);
      setPhotoOpenDialog(true);
    });
  }, []);

  const handleOpenVideoDialog = useCallback((message: DialogMessage) => {
    checkBalance(creditsDialogVideoOpen, () => {
      setRevealVideoMessage(message);
      setVideoOpenDialog(true);
    });
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [referenceUser, userDialog.data]);

  const getReferenceUserDialogCallback = useCallback(async () => {
    if (userDialog.nextPage) {
      await getReferenceUserDialog(referenceUser.id, userDialog.data.length);
    }
  }, [userDialog.nextPage, userDialog.data.length, getReferenceUserDialog, referenceUser.id]);

  return (
    <div className={classes.scrollContainer}>
      {userDialog.nextPage && (
        <div className={classes.loadMorePhoto} onClick={getReferenceUserDialogCallback}>
          {' '}
          load previous messages
        </div>
      )}
      {(userDialog.data || []).map((message: DialogMessage) => (
        <div ref={messagesEndRef} key={message.id}>
          {message.senderProfile.id === currentUser?.id && (
            <DialogMessageContainer isClient id={message.id} >
              <div
                className={clsx(classes[currentUser.gender], classes.rightMessageBackground, classes.messageWrapper)}
              >
                <div className={classes.messageBody}>
                  {message.translate || message.text}
                  {message.photo && message.photo?.small ? (
                      <div className={classes.profileSlideWrapper}>
                        <Gallery>
                          <ProfileUserSlider
                              defaultValue={[message.photo]}
                              windowHeight={windowHeight - 90}
                              windowWidth={windowWidth}
                          />
                        </Gallery>
                      </div>
                    ) : (
                    ''
                    )}
                  {message.video && message.video?.thumbnail && message.video?.origin ? (
                      <div>
                      <UploadVideoItem
                        id={message?.video.id}
                        isExpended={false}
                        thumbnail={message?.video?.thumbnail}
                        isPrivate={true}
                        withoutIcon={true}
                        isEditable={false}
                        origin={message?.video?.origin}
                        itemContainerStyle={classes.dropzoneVideo}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  {message.virtualGift && (
                    <div>
                      <img className={classes.virtualGift} alt={message.virtualGift.image} src={message.virtualGift.image} />
                    </div>
                  )}
                </div>
                <div className={classes.time}>{formatAMPM(message.createdAt)}</div>
              </div>
              <DialogAvatar isClient avatar={currentUser.avatar} />
            </DialogMessageContainer>
          )}
          {message.senderProfile.id === referenceUser.id &&
            message.photo &&
            message.photo.id && (
              <DialogPhoto
                messageId={message.id}
                photoData={message.photo}
                createdAt={message.createdAt}
                senderAvatar={message.senderProfile.avatar}
                gender={referenceUser.gender}
                windowHeight={windowHeight}
                windowWidth={windowWidth}
                payForDialogPhoto={() => handleOpenPhotoDialog(message)}
              />
            )}
          {message.senderProfile.id === referenceUser.id &&
            message.video &&
            message.video.id && (
              <DialogVideo
                  payForDialogVideo={() => handleOpenVideoDialog(message)}
                  messageId={message.id}
                  videoData={message.video}
                  createdAt={message.createdAt}
                  senderAvatar={message.senderProfile.avatar}
                  gender={referenceUser.gender}
              />
            )}
          {message.senderProfile.id === referenceUser.id && !message.photo && !message.video && (
              <DialogMessageContainer id={message.id}>
                <DialogAvatar avatar={message.senderProfile.avatar} />
                <div
                    className={clsx(classes[referenceUser.gender], classes.leftMessageBackground, classes.messageWrapper)}
                >
                  <div className={classes.messageBody}>
                    {message.translate || message.text}{' '}
                    {message.virtualGift && (
                        <div>
                          <img className={classes.virtualGift} alt={message.virtualGift.image} src={message.virtualGift.image}/>
                        </div>
                    )}
                  </div>

                  <div className={classes.time}>{formatAMPM(message.createdAt)}</div>
                </div>
              </DialogMessageContainer>
          )}
          {![currentUser?.id, referenceUser.id].includes(message.senderProfile.id) &&
              ![currentUser?.id, referenceUser.id].includes(message.receiverProfile.id) && (
                  <div
                      className={clsx(classes.customMessage)}
                      key={message.createdAt as unknown as string}
                onClick={() => {}}
              >
                {message.text}
              </div>
            )}
        </div>
      ))}
      <RevealPhotoComponent
        handleCloseDialog={handleClosePhotoDialog}
        openDialog={openPhotoDialog}
        message={revealPhotoMessage}
        revealPhoto={revealPhotoCallback}
      />
      <RevealVideoComponent
        handleCloseDialog={handleCloseVideoDialog}
        openDialog={openVideoDialog}
        message={revealVideoMessage}
        revealVideo={revealVideoCallback}
      />
    </div>
  );
};

export default DialogMessages;
