import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserProfileDTO } from '@/app/users/users.reducer';
import UserCard from './user-card.component';
import { useAppSelector } from '@/app/hooks';
import { useSelector } from "react-redux";
import { selectUserBookmarks } from "@/app/users/users.selectors";

type UserLinkProps = {
  userName: string;
  uid: string;
  user: UserProfileDTO;
  avatar: string;
  pageId: string;
  isNewMessage: boolean;
  onClick: () => void;
};

const UserLink = ({
                    userName, uid, user, avatar, pageId, onClick = () => {
  }, isNewMessage
                  }: UserLinkProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const usersWithActiveTextChat =
    useAppSelector((state) => state.users.usersWithActiveTextChat) || [];

  const bookmarks = useSelector(selectUserBookmarks);

  useEffect(() => {
    setIsActive((usersWithActiveTextChat.map((userWithActiveChat) => userWithActiveChat.id)).includes(uid));
  }, [uid, user, usersWithActiveTextChat]);

  const [isActive, setIsActive] = useState(false)

  const isSelected = location.pathname.includes(uid);
  const route = `/chat/${user?.profile?.id}/${uid}`;

  const isInBookmarks = bookmarks.map(bookmark => bookmark.id).includes(uid);

  const handleClick = useCallback(
    () => {
      navigate(route);
      onClick();
    },
    [route, navigate, onClick]
  );

  return (
    <UserCard
      handleClick={handleClick}
      userName={userName}
      uid={uid}
      pageId={pageId}
      avatar={avatar}
      isSelected={isSelected}
      isActive={isActive}
      isNewMessage={isNewMessage}
      isInBookmarks={isInBookmarks}
    />
  );
};

export default UserLink;
