export const socketRoutes = {
  // Default message from client
  dialogNotificationClient: 'notifications:newDialog:client',
  // Dist message from client
  dialogNotificationDistribution: 'notifications:newDialog:distribution',
  // Autoinvite message from client
  dialogNotificationAutoinvite: 'notifications:newDialog:autoinvite',
  // Message that the user send to client
  dialogNotificationReceiveOwn: 'notifications:newDialog:receiveOwn',
  // Dialog counter
  dialogNotificationCounter: 'notifications:counter:dialogs',
}
