import React, { Fragment } from "react";
import Invitations from '../base/invitations.component';
import ChatHeader from '../chat-container/components/chat-header.component';
import GeneralSnackbar from "../core/snackbar";
import ModalBar from '../core/modal-bar';
import DialogAutoInvites from "@/components/base/DialogAutoInvites/DialogAutoInvites";

type MainLayoutProps = {
  children: React.ReactNode;
};

const ChatLayout = ({ children }: MainLayoutProps) => {

  return (
    <Fragment>
      <GeneralSnackbar />
      <ModalBar />
      <ChatHeader />
      {children}
      <DialogAutoInvites />
    </Fragment>
  );
};

export default ChatLayout;
