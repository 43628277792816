import React, { FC, useEffect } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import profilePicture from "@/assets/images/demo-avatar.jpeg";
import { Slide } from '@mui/material';
import clsx from "clsx";
import { useStyles } from "@/components/base/DialogAutoInvites/styles";
import { calculateAge } from "@/helpers/helpers";
import { DialogInviteMessage } from "@/types/user-profile.types";

export type DialogAutoInvitesMobileProps = {
  invite: DialogInviteMessage,
  handleSkip: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, invite: DialogInviteMessage) => void,
  handleItemClick: (invite: DialogInviteMessage) => void,
  isExpanded: boolean,
  showItems: boolean,
  isSemiExpanded: boolean,
  removeSemiExpandedItem: (id: string) => void
}

const DialogAutoInvitesItem: FC<DialogAutoInvitesMobileProps> = ({
                                                                   invite,
                                                                   handleSkip,
                                                                   handleItemClick,
                                                                   isExpanded,
                                                                   showItems,
                                                                   isSemiExpanded,
                                                                   removeSemiExpandedItem,
                                                                 }) => {
  const classes = useStyles();
  const { senderProfile: { avatar, birthday, name }, text, id } = invite

  const iconSx = { width: 18, height: 18 }

  useEffect(() => {
    let timeout = null
    if (isSemiExpanded) {
      setTimeout(() => removeSemiExpandedItem(id), 7000)
    }

    return () => clearTimeout(timeout)
  }, [id, isSemiExpanded, removeSemiExpandedItem]);

  const trimText = (text: string, maxLength = 50) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text
  }

  return (
    <Slide
      direction="left"
      in={showItems}
      timeout={300}
      unmountOnExit
    >
      <div
        className={clsx(
          classes.itemContainer,
          { [classes.itemContainerExpanded]: isExpanded },
          { [classes.itemContainerSemiExpanded]: isSemiExpanded },
        )}
        onClick={() => handleItemClick(invite)}
      >
        <p className={classes.itemSemiExpandedMessage}>{trimText(text, 15)}</p>
        <img
          className={classes.itemAvatar}
          alt="avatar"
          src={avatar || profilePicture}
        />
        <div className={classes.itemTextContainer}>
          <p className={classes.itemTextName}>
            {`${name}, ${calculateAge(birthday)}`}
          </p>
          <p className={classes.itemTextMessage}>{trimText(text)}</p>
        </div>
        <div
          className={classes.closeContainer}
          onClick={(e) => handleSkip(e, invite)}
        >
          <CloseIcon className={classes.closeIcon} sx={iconSx} />
        </div>
      </div>
    </Slide>
  );
};

export default DialogAutoInvitesItem;
