import React, { memo } from 'react';
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as InviteIcon } from '@/assets/icons/mobileInvites.svg';
import { Fade } from '@mui/material';
import { useStyles } from "@/components/base/DialogAutoInvites/styles";
import { useDialogAutoInvitesData } from "@/components/base/DialogAutoInvites/hooks/useDialogAutoInvitesData";
import DialogAutoInvitesItem from "@/components/base/DialogAutoInvites/components/DialogAutoInvitesItem";

const DialogAutoInvites = () => {
  const classes = useStyles();
  const {
    isVisible,
    visibleInvites,
    showItems,
    toggleItems,
    expandedItems,
    handleItemClick,
    handleSkip,
    semiExpandedItems,
    removeSemiExpandedItem
  } = useDialogAutoInvitesData()

  const iconCloseSx = { width: 30, height: 30, }
  const iconStyle = { width: 39, height: 39, }

  return (
    <Fade in={isVisible} timeout={500}>
      <div className={classes.container}>
        <div className={classes.inviteIcon} onClick={toggleItems}>
          <div className={classes.inviteIconCounter}>
            {visibleInvites.length}
          </div>
          <InviteIcon
            style={iconStyle}
          />
          {showItems ? <CloseIcon className={classes.inviteIconClose} sx={iconCloseSx} /> : null}
        </div>
        {visibleInvites.map((invite) => (
          <DialogAutoInvitesItem
            key={invite.id}
            invite={invite}
            handleSkip={handleSkip}
            handleItemClick={handleItemClick}
            isExpanded={expandedItems.includes(invite.id)}
            showItems={showItems}
            isSemiExpanded={semiExpandedItems.includes(invite.id)}
            removeSemiExpandedItem={removeSemiExpandedItem}
          />
        ))}
      </div>
    </Fade>
  );
};

export default memo(DialogAutoInvites);
