import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { filterInappRequest, updateInappRequest } from '@/app/users/users.actions';
import { useCallback, useEffect } from 'react';
import { InAppDialog } from './chat-container/components/inapp-dialog.component';
import { useSelector } from "react-redux";
import { selectDialogInvitesActive } from "@/app/dialogInvites/dialogInvites.selectors";

const InappSubscriber = () => {
  const currentUser = useAppSelector((state) => state.users.currentUser);
  const inAppMessages = useAppSelector((state) => state.users.inAppMessages);
  const dialogInvites = useSelector(selectDialogInvitesActive);

  const dispatch = useAppDispatch();

  const init = useCallback(() => {
    dispatch(updateInappRequest());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      init();
    }
  }, [currentUser, init]);

  const onCloseHandler = useCallback(() => {
    dispatch(filterInappRequest(inAppMessages.data[0].id));

  }, [dispatch, inAppMessages.data]);

  if (inAppMessages.isLoading || !inAppMessages.data.length || dialogInvites.length > 0) return null;

  return (
    <InAppDialog
      title={inAppMessages.data[0].subject}
      body={inAppMessages.data[0].body}
      onClose={onCloseHandler}
      open={!!inAppMessages.data[0]}
    />
  );
};

export default InappSubscriber;
