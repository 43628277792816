import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    userSelect: 'none',
    '-webkit-tap-highlight-color': 'transparent',
    width: 'auto',
    zIndex: 1001,
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    top: 30,
    right: 8,
    cursor: 'pointer',
    gap: '16px',
    [theme.breakpoints.down('lg')]: {
      bottom: 30,
      top: 'unset',
      paddingBottom: '2px',
      flexDirection: 'column-reverse',
    },
    [theme.breakpoints.down('sm')]: {
      bottom: 84,
      right: 8,
      gap: '6px',
    },
  },
  itemContainer: {
    userSelect: 'none',
    display: 'flex',
    flexDirection: 'row',
    boxShadow: "0px 0px 20px 0px #0000000D",
    padding: '3px',
    gap: '12px',
    background: '#FFFFFF',
    alignItems: 'center',
    position: 'relative',
    width: '56px',
    alignSelf: 'flex-end',
    borderRadius: '100px',
    [theme.breakpoints.down('lg')]: {
      alignSelf: 'flex-end',
    },
  },
  itemContainerExpanded: {
    transition: 'width 0.3s ease !important',
    width: '290px',
    '& $itemTextContainer': {
      paddingRight: '12px',
      opacity: '1',
    },
    '& $closeContainer': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      top: -6,
      right: -6,
    },
  },
  itemContainerSemiExpanded: {
    position: 'relative',
    '& $itemSemiExpandedMessage': {
      display: 'block',
      position: 'absolute',
      paddingRight: '6px',
      paddingLeft: '6px',
      right: 'calc(100% + 4px)',
      fontFamily: "'Readex Pro', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
      fontWeight: 400,
      fontSize: 14,
      lineHeight: "20px",
      whiteSpace: 'nowrap',
      borderRadius: '12px',
      backgroundColor: 'rgba(238,238,238,.7)',
    },
  },
  itemSemiExpandedMessage: {
    display: 'none',
  },
  itemAvatar: {
    minWidth: '56px',
    maxWidth: '56px',
    height: '56px',
    borderRadius: '56px',
    objectFit: 'cover',
  },
  itemTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    opacity: '0',
    overflow: 'hidden',
    width: '218px'
  },
  itemTextName: {
    fontFamily: "'Readex Pro', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "20px",
    color: '#000000',
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    userSelect: "none",
    width: '218px'
  },
  itemTextMessage: {
    fontFamily: "'Readex Pro', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "20px",
    color: '#00000080',
    margin: 0,
    maxHeight: '40px',
    userSelect: "none",
    wordBreak: 'break-all',
    width: '218px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('lg')]: {
      whiteSpace: 'nowrap',
    },
  },
  closeContainer: {
    position: 'absolute',
    width: 24,
    height: 24,
    borderRadius: '24px',
    border: "1.8px solid #DEDEDE",
    background: '#DEDEDE',
    display: 'none',
  },
  closeIcon: {
    color: '#503EB6',
  },
  inviteIcon: {
    userSelect: 'none',
    background: '#FFFFFF',
    width: 38,
    height: 38,
    borderRadius: '50%',
    position: 'relative',
    left: 'calc(100% - 49px)',
    [theme.breakpoints.down('lg')]: {
      left: 'calc(100% - 51px)',
    },
  },
  inviteIconCounter: {
    fontSize: 14,
    lineHeight: '14px',
    fontFamily: "'Readex Pro', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
    fontWeight: 600,
    color: '#FFFFFF',
    background: '#EA4160',
    padding: '2px 4px',
    borderRadius: 100,
    position: 'absolute',
    top: '-6px',
    right: '-4px',
    zIndex: 2,
  },
  inviteIconClose: {
    position: 'absolute',
    color: '#503EB6',
    left: '4.5px',
    top: '4.5px',
    background: '#FFF',
    borderRadius: '50%'
  }
}));
