import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./rootReducer";
import rootSaga from "./rootSaga";
import { persistStore } from "redux-persist";
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";
import {
  GET_DIALOG_INVITES_SUCCESS,
  SET_DIALOG_INVITE_ACTIVE_DATA,
  SET_SKIPPED_INVITES_DATA,
} from "@/app/dialogInvites/dialogInvites.types";

const sagaMiddleware = createSagaMiddleware();
const stateSyncMiddleware = createStateSyncMiddleware({
  whitelist: [GET_DIALOG_INVITES_SUCCESS, SET_DIALOG_INVITE_ACTIVE_DATA, SET_SKIPPED_INVITES_DATA],
});
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware, stateSyncMiddleware))
);
export const persistor = persistStore(store);

initMessageListener(store);
sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export default store;
