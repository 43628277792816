import { useAppBreakpoints } from "@/hooks";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment } from "react";
import Invitations from '../base/invitations.component';
import GeneralSnackbar from "../core/snackbar";
import Footer from "../footer.component";
import Header from "../header.component";
import { useAppSelector } from '@/app/hooks';
import DialogInvitation from '../base/dialog-invitations.component';
import ModalBar from '../core/modal-bar';
import DialogAutoInvites from "@/components/base/DialogAutoInvites/DialogAutoInvites";

type MainLayoutProps = {
  children: React.ReactNode;
};
const useStyles = makeStyles((theme: Theme) => ({}));

const MainLayout = ({ children }: MainLayoutProps) => {
  const classes = useStyles();
  const { smAndDown, mdAndDown, lg } = useAppBreakpoints();
  const currentUser = useAppSelector((state) => state.users.currentUser);

  return (
    <Fragment>
      <GeneralSnackbar />
      <ModalBar />
      <Header />
      {children}
      {currentUser && <Invitations />}
      {currentUser && <DialogInvitation />}
      {currentUser && <DialogAutoInvites />}
      <Footer />
    </Fragment>
  );
};

export default MainLayout;
