import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@/app/hooks';
import { toggleAvailableUsersBar } from '@/app/ui/ui.actions';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { ReactComponent as ArrowDown } from '@/assets/icons/arrow-down.svg';
import { useBinarySwitcher } from '@/hooks/useBinarySwitcher';
import InvitesCard from './invites-card.component';
import useSound from 'use-sound';
import inviteSound from '@/assets/sounds/invite.mp3';
import { StorageService } from '@/services/storage.service';
import { useSelector } from "react-redux";
import { selectDialogInvitesActive } from "@/app/dialogInvites/dialogInvites.selectors";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: '324px',
    width: '324px',
    overflow: 'hidden',
    zIndex: 1000,
    background: '#FFFFFF',
    borderRadius: '10px 10px 10px 10px',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'fixed',
    bottom: 0,
    right: 0,
  },
  headerInvitation: {
    background: '#503EB6',
    width: '100%',
    borderRadius: '10px',
    display: 'flex',
    color: 'white',
    justifyContent: 'space-between',
    padding: '18px',
  },
  title: {
    color: 'white',
    fontSize: '16px',
    lineHeight: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '16px',
  },
  counter: {
    color: 'white',
    fontSize: '11px',
    lineHeight: '18px',
    minWidth: '30px',
    background: '#EA4160',
    borderRadius: '20px',
    marginLeft: '5px',
    textAlign: 'center',
  },
  arrow: {
    background: '#EEEEEE',
    width: '28px',
    height: '28px',
    borderRadius: '28px',
    textAlign: 'center',
    marginRight: '18px',
  },
  arrowUp: {
    background: '#EEEEEE',
    width: '28px',
    height: '28px',
    borderRadius: '28px',
    textAlign: 'center',
    marginRight: '18px',
    transform: 'rotate(180deg)',
  },
}));

const Invitations = () => {
  const classes = useStyles();
  const invites = useAppSelector((state) => state.users.invites);
  const dialogInvites = useSelector(selectDialogInvitesActive);

  const [isOpen, setIsOpen, setIsClose] = useBinarySwitcher(false);

  const [playbackRate] = useState(1);

  const [play] = useSound(inviteSound, {
    playbackRate,
    interrupt: true,
  });


  const usePrevious = (value = 0) => {
    const ref = useRef(0);
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const invitesLength = usePrevious(invites.length) || 0;

  useEffect(() => {
    const isAudioTurnOn = StorageService.getAudio();
    if (isAudioTurnOn && invitesLength < invites.length) {
      play();
    }
  }, [invites.length, invitesLength, play])


  if (dialogInvites.length > 0 || !invites || (invites && invites.length === 0)) {
    return null;
  }

  const getEndTime = (time: string) => {
    return new Date(time).getTime() + 60000;
  };

  const getTimeStart = (time: string) => {
    return new Date(time).getTime();
  };

  return (
    <div className={classes.container}>
      {isOpen ? (
        <>
          <div className={classes.headerInvitation} onClick={setIsClose}>
            <div className={classes.title}>
              <span>Invitations</span>
              <div className={classes.counter}>{invites.length}</div>
            </div>
            <div className={classes.arrowUp}>
              <ArrowDown />
            </div>
          </div>
        </>
      ) : (
        <>
          {invites.map((invite) => (
            <InvitesCard
              invite={invite}
              key={invite.chat[0].date}
              timeEnd={getEndTime(invite.chat[0].date)}
              timeStart={getTimeStart(invite.chat[0].date)}
            />
          ))}
          <div className={classes.headerInvitation} onClick={setIsOpen}>
            <div className={classes.title}>
              <span>Invitations</span>
              <div className={classes.counter}>{invites.length}</div>
            </div>
            <div className={classes.arrow}>
              <ArrowDown />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Invitations;
