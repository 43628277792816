import { DialogInviteMessage, DialogMessage } from "@/types/user-profile.types";

export const generateRandomInviteTime = (currentTime?: number): number => {
  const time = currentTime || new Date().getTime();
  const minMinutes = 1;
  const maxMinutes = 2;
  const randomMinutes = Math.floor(Math.random() * (maxMinutes - minMinutes + 1)) + minMinutes;
  const randomSeconds = Math.floor(Math.random() * 60);

  return time + (randomMinutes * 60 + randomSeconds) * 1000;
};

export const prepareRandomInviteData = (
  invites: DialogMessage[],
  shouldSkip?: DialogInviteMessage[]
): DialogInviteMessage | null => {
  if (!invites || invites.length === 0) return null;

  const shouldSkipIds = new Set(shouldSkip?.map((invite) => invite.id));
  const skippedSenderIds = new Set(
    shouldSkip?.map((invite) => invite.senderProfile.id)
  );
  const latestMessagesBySender = new Map<string, DialogMessage>();

  for (const message of invites) {
    const senderId = message.senderProfile.id;
    if (shouldSkipIds.has(message.id) || skippedSenderIds.has(senderId)) {
      continue;
    }

    if (
      !latestMessagesBySender.has(senderId) ||
      message.createdAt > latestMessagesBySender.get(senderId)!.createdAt
    ) {
      latestMessagesBySender.set(senderId, message);
    }
  }

  const uniqueMessages = Array.from(latestMessagesBySender.values());

  if (uniqueMessages.length === 0) return null;

  const randomMessage = uniqueMessages[Math.floor(Math.random() * uniqueMessages.length)];

  return {
    ...randomMessage,
    endTime: generateRandomInviteTime(),
  };
};

