import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@/app/hooks';
import { toggleAvailableUsersBar } from '@/app/ui/ui.actions';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { ReactComponent as ArrowDown } from '@/assets/icons/arrow-down.svg';
import { useBinarySwitcher } from '@/hooks/useBinarySwitcher';
import useDialogPersistentTab from './use-dialog-persistance-tab-hook';
import { useSelector } from "react-redux";
import { selectDialogInvitesActive } from "@/app/dialogInvites/dialogInvites.selectors";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: '324px',
    width: '324px',
    overflow: 'hidden',
    zIndex: 1000,
    background: '#FFFFFF',
    borderRadius: '10px 10px 10px 10px',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'fixed',
    top: 100,
    right: 0,
    cursor: 'pointer',
  },
  headerInvitation: {
    background: '#503EB6',
    width: '100%',
    borderRadius: '10px',
    display: 'flex',
    color: 'white',
    justifyContent: 'space-between',
    padding: '18px',
  },
  title: {
    color: 'white',
    fontSize: '16px',
    lineHeight: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '16px',
  },
  counter: {
    color: 'white',
    fontSize: '11px',
    lineHeight: '18px',
    minWidth: '30px',
    background: '#EA4160',
    borderRadius: '20px',
    marginLeft: '5px',
    textAlign: 'center',
  },
  arrowUp: {
    background: '#EEEEEE',
    width: '28px',
    height: '28px',
    borderRadius: '28px',
    textAlign: 'center',
    marginRight: '18px',
    transform: 'rotate(180deg)',
  },
}));

const DialogInvitation = () => {
  const classes = useStyles();
  const inboxNewDialogCount = useAppSelector((state) => state.users.dialogCounters?.unseen);
  const dialogInvites = useSelector(selectDialogInvitesActive);

  const [isOpen, setIsOpen, setIsClose] = useBinarySwitcher(false);

  const [openDialogTab] = useDialogPersistentTab(`/dialog`);

  const closeWithOpenDialogCallback = useCallback(() => {
    setIsClose();
    openDialogTab(`/dialog`);
  }, []);

  const changeIsOpenToOpposite = useCallback(() => {
    isOpen ? setIsClose() : setIsOpen();
  }, [isOpen, setIsClose, setIsOpen]);

  const [intervalId, setIntervalId] = useState(null);

  const setIntervalAfterClose = useCallback(() => {
    setIsClose();
    clearInterval(intervalId);
    setIntervalId(
      setInterval(() => {
        inboxNewDialogCount && setIsOpen();
      }, 600000)
    );
  }, [inboxNewDialogCount, setIsOpen]);

  useEffect(() => {
    setIntervalId(
      setInterval(() => {
        inboxNewDialogCount && changeIsOpenToOpposite();
      }, 300000)
    );

    return () => clearInterval(intervalId);
  }, []);

  if (dialogInvites.length > 0) {
    return null
  }

  return (
    <div className={classes.container}>
      {isOpen && inboxNewDialogCount ? (
        <>
          <div className={classes.headerInvitation}>
            <div className={classes.title} onClick={closeWithOpenDialogCallback}>
              <span>Unread dialogs</span>
              <div className={classes.counter}>{inboxNewDialogCount}</div>
            </div>
            <div className={classes.arrowUp} onClick={setIntervalAfterClose}>
              <ArrowDown />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default DialogInvitation;
