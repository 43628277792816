import { RootState } from "@/app/rootReducer";
import { DialogInviteMessage, DialogMessage } from "@/types/user-profile.types";

export const selectDialogInvitesActive = (state: RootState): DialogInviteMessage[] => {
  return state.dialogInvites.visibleDialogInvites
}

export const selectDialogInvites = (state: RootState): DialogMessage[] => {
  return state.dialogInvites.dialogInvites
};

export const selectDialogInvitesData = (state: RootState): DialogMessage[] => {
  return state.dialogInvites.dialogInvites?.data || []
};

export const selectSkippedInvites = (state: RootState): DialogMessage[] => {
  return state.dialogInvites.skippedInvites
};
