import { RootState } from "@/app/rootReducer";
import { createSelector } from "@reduxjs/toolkit";

export const selectCurrentUser = createSelector(
  (state: RootState) => state.users,
  (users) => users.currentUser
);

export const selectUserBookmarks = createSelector(
  (state: RootState) => state.users,
  (users) => users.bookmarks
);

export const selectUserDialogs = createSelector(
  (state: RootState) => state.users,
  (users) => users.userDialogs
);

export const selectAvailableUsers = createSelector(
  (state: RootState) => state.users,
  (users) => users.availableUsers
);

export const selectSearchUsersData = createSelector(
  (state: RootState) => state.users,
  (users) => users.searchUsers.data
);
