import ProfilesService from '@/services/profiles.service';
import { DialogInviteMessage, UserDialog } from '@/types/user-profile.types';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { toggleSnackbarOpen } from '../ui/ui.actions';
import {
  getDialogInvitesSuccess,
  skipDialogInviteActiveAction,
  readUserActiveDialogInvitesAction,
  setDialogInviteActiveData,
  updateDialogInviteSocketData,
  setSkippedInvitesData,
  setUpdatedDialogInvitesData,
} from '@/app/dialogInvites/dialogInvites.actions';
import {
  GENERATE_NEW_DIALOG_INVITE_ACTIVE_DATA,
  GET_DIALOG_INVITES_REQUEST,
  SKIP_DIALOG_INVITE_ACTIVE_ACTION,
  READ_USER_ACTIVE_DIALOG_INVITES,
  UPDATE_DIALOG_INVITE_SOCKET_DATA,
  HIDE_DIALOG_INVITE_ACTIVE_ACTION,
} from '@/app/dialogInvites/dialogInvites.types';
import {
  selectDialogInvites,
  selectDialogInvitesActive,
  selectDialogInvitesData, selectSkippedInvites
} from "@/app/dialogInvites/dialogInvites.selectors";
import {
  generateRandomInviteTime,
  prepareRandomInviteData
} from "@/utils/generateRandomInviteTime";

function* getDialogInvitesSaga() {
  try {
    const dialogInvites: UserDialog = yield call(() => ProfilesService.getDialogInvites());
    const visibleDialogInvites = yield select(selectDialogInvitesActive);
    const skippedInvites = yield select(selectSkippedInvites);
    const currentTime = new Date().getTime();
    const updatedSkippedInvites = skippedInvites.filter((invite) => {
      return invite.endTime > currentTime
    });
    let preparedInvite: DialogInviteMessage | null = null
    if (visibleDialogInvites.length === 0 && dialogInvites.data.length > 0) {
      preparedInvite = prepareRandomInviteData(dialogInvites.data, updatedSkippedInvites);
    }

    yield all([
      put(getDialogInvitesSuccess(dialogInvites)),
      preparedInvite ? put(setDialogInviteActiveData({ visibleDialogInvites: [preparedInvite] })) : null
    ]);
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
  }
}

function* updateDialogInviteSocketDataSaga({ payload }: ReturnType<typeof updateDialogInviteSocketData>) {
  const { message } = payload;
  const invitesData: UserDialog = yield select(selectDialogInvites);

  yield put(getDialogInvitesSuccess({
    ...invitesData,
    data: [
      ...invitesData.data.filter(invite => invite.senderProfile.id !== message.senderProfile.id), message
    ],
  }))
}

function* generateNewDialogInviteActiveDataSaga() {
  const invitesData = yield select(selectDialogInvitesData);
  const visibleDialogInvites = yield select(selectDialogInvitesActive);
  const skippedInvites = yield select(selectSkippedInvites);

  const currentTime = new Date().getTime();
  const updatedSkippedInvites = skippedInvites.filter((invite) => {
    return invite.endTime > currentTime
  });

  if (visibleDialogInvites.length < 5 && invitesData.length > 0) {
    const preparedInvite = prepareRandomInviteData(
      invitesData,
      [...visibleDialogInvites, ...updatedSkippedInvites]
    );

    if (preparedInvite) {
      yield put(setUpdatedDialogInvitesData({
        visibleDialogInvites: [...visibleDialogInvites, preparedInvite],
        skippedInvites: updatedSkippedInvites,
      }))
    }
  }
}

function* readUserDialogInvitesDataSaga({ payload }: ReturnType<typeof readUserActiveDialogInvitesAction>) {
  const { id } = payload;
  const dialogInvites = yield select(selectDialogInvites);
  const visibleInvites = yield select(selectDialogInvitesActive);

  yield all([
    put(getDialogInvitesSuccess({
      ...dialogInvites,
      data: dialogInvites.data.filter((invite) => invite.senderProfile.id !== id)
    })),
    put(setDialogInviteActiveData({
      visibleDialogInvites: visibleInvites.filter((invite) => invite.senderProfile.id !== id),
    })),
  ]);
}

function* hideDialogInviteActiveDataItemSaga({ payload }: ReturnType<typeof skipDialogInviteActiveAction>) {
  const { dialogInvite } = payload
  const visibleInvites: DialogInviteMessage[] = yield select(selectDialogInvitesActive);

  yield put(setDialogInviteActiveData({
    visibleDialogInvites: visibleInvites.filter((invite) => invite.id !== dialogInvite.id),
  }))
}

function* skipDialogInviteActiveDataItemSaga({ payload }: ReturnType<typeof skipDialogInviteActiveAction>) {
  const { dialogInvite } = payload
  const visibleInvites: DialogInviteMessage[] = yield select(selectDialogInvitesActive);
  const skippedInvites: DialogInviteMessage[] = yield select(selectSkippedInvites);
  const currentTime = new Date().getTime();
  const newEndTime = generateRandomInviteTime(currentTime)
  const updatedSkippedInvites = skippedInvites.filter((invite) => invite.endTime > currentTime);

  yield put(setSkippedInvitesData({
    visibleDialogInvites: visibleInvites.filter((invite) => invite.id !== dialogInvite.id),
    skippedInvites: [...updatedSkippedInvites, { ...dialogInvite, endTime: newEndTime }],
  }))
}

function* getDialogInvites() {
  yield takeLatest(GET_DIALOG_INVITES_REQUEST, getDialogInvitesSaga);
}

function* updateDialogInvitesSocket() {
  yield takeLatest(UPDATE_DIALOG_INVITE_SOCKET_DATA, updateDialogInviteSocketDataSaga);
}

function* generateNewDialogInviteActiveData() {
  yield takeLatest(GENERATE_NEW_DIALOG_INVITE_ACTIVE_DATA, generateNewDialogInviteActiveDataSaga);
}

function* readUserDialogInvitesData() {
  yield takeLatest(READ_USER_ACTIVE_DIALOG_INVITES, readUserDialogInvitesDataSaga);
}

function* skipDialogInviteActiveDataItem() {
  yield takeLatest(SKIP_DIALOG_INVITE_ACTIVE_ACTION, skipDialogInviteActiveDataItemSaga);
}

function* hideDialogInviteActiveDataItem() {
  yield takeLatest(HIDE_DIALOG_INVITE_ACTIVE_ACTION, hideDialogInviteActiveDataItemSaga);
}

const dialogInvitesSagas = [
  fork(getDialogInvites),
  fork(updateDialogInvitesSocket),
  fork(generateNewDialogInviteActiveData),
  fork(readUserDialogInvitesData),
  fork(skipDialogInviteActiveDataItem),
  fork(hideDialogInviteActiveDataItem),
];

export default dialogInvitesSagas;
